html,
body,
#__next {
	scroll-behavior: smooth !important;
	scroll-padding-top: 90px;
	-webkit-font-smoothing: antialiased;
}

/* Chrome autofill */
/* input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
	-webkit-box-shadow: 0 0 0 30px white inset !important;
} */

.notistackContainer {
	margin-top: 60px;
}

@media screen and (max-width: 768px) {
	.notistackContainer {
		align-items: center;
	}
}

/* FIX FRONT APP */
.hide-frontapp-chat #front-chat-iframe {
	display: none !important;
}

@media (max-width: 600px) {
	#front-chat-iframe {
		display: none !important;
	}
}

iframe {
	pointer-events: auto;
}

/*Toaster*/

.toaster [data-type="success"] [data-icon] {
	color: #22c55e;
}

.toaster [data-type="error"] [data-icon] {
	color: #ff4338;
}
